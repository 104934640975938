import React, { useState } from 'react';
import { NavLink, Link } from "react-router-dom";

import Logo from '../img/logo.png';
import Menu from '../img/hamburger-menu.png';

import navigationData from '../data/header.json';

import '../scss/components/header.scss';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const capitaliseFirstLetter = (string) => {
    let formattedString = string.charAt(0).toUpperCase() + string.slice(1);
    return formattedString.split('-').join(' ');
  };

  const handleToggleMenu = (desktop) => {
    if (!desktop) {
      setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
    }
  };

  return (
    <header className={`header ${isMenuOpen ? `header--open` : ''}`}>
      <div className="header__wrapper container">
        <img 
          src={Menu} 
          className="header__menu" 
          alt="Menu Icon" 
          onClick={() => handleToggleMenu()}
        />
        <Link to='/'>
          <img src={Logo} className="header__logo" alt="Bollington Town FC logo" />
        </Link>
        <ul className="header__link-container">
          {navigationData.map((data, iterator) => (
            <li key={iterator} onClick={() => handleToggleMenu('desktop')} className='header__link'>
              <NavLink 
                onClick={() => setIsMenuOpen(false)} 
                isActive={(match, location) => location.pathname === '/' && data === 'home' ? true : !!match} 
                to={`/${data}`}
              >
                {capitaliseFirstLetter(data)}
              </NavLink>
            </li> 
          ))}

          {/* Matches Dropdown */}
          <li 
            className='header__dropdown'
            onMouseEnter={() => setIsDropdownOpen(true)}
            onMouseLeave={() => setIsDropdownOpen(false)}
          >
            <span>Matches</span>
            <ul className={`header__dropdown-menu ${isDropdownOpen ? 'visible' : ''}`}>
              <li>
                <a href="https://fulltime.thefa.com/displayTeam.html?divisionseason=80570566&teamID=312011849" target="_blank" rel="noreferrer">
                  First Team
                </a>
              </li>
              <li>
                <a href="https://fulltime.thefa.com/displayTeam.html?divisionseason=660636934&teamID=832848102" target="_blank" rel="noreferrer">
                  Reserves
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
