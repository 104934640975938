import React, { useEffect, useState } from "react";
import { client } from "../sanityClient";

import OurJourney from '../components/home/banner';
import NextMatch from './../components/home/next-match';
import GetInvolved from './../components/home/get-involved';
import Row from './../components/row-content';

import '../scss/components/instagram.scss';
import Squad from '../img/squad.jpg'

const POSTS_QUERY = `*[_type == "blogPost"]{
  title,
  "imageUrl": image.asset->url,
  "altText": image.alt,
  description,
  publishedAt
} | order(publishedAt desc)`;

const Home = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    client.fetch(POSTS_QUERY)
      .then((data) => setPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div>
      <OurJourney
        heading={'Our Journey so far'}
        body='From conception to trophy triumph. Find out more about our journey, and plans for the future'
        buttonLink='mailto:info@bollingtontownfc.co.uk'
        buttonText='Find out more'
        image={Squad}
        imageAlt='Bollington Town Squad Photo'
      />
      <Row
        image={posts[0]?.imageUrl}
        imageAlt=''
        heading={posts[0]?.title}
        imageModifier='small'
        body={posts[0]?.description}/>
      <NextMatch/>
      <div className="container">
        <div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" className="crt-logo crt-tag" rel="noreferrer"></a></div>
      </div>
      <GetInvolved/>
      <Row
        image={posts[1]?.imageUrl}
        imageAlt=''
        heading={posts[1]?.title}
        styleModifier='reverse' 
        imageModifier='small'
        body={posts[1]?.description}/>
    </div>
  );
}

export default Home;