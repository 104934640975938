/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { client } from "../sanityClient";
import '../scss/pages/squad.scss';

const Squad = () => {
  const [selectedTeam, setSelectedTeam] = useState('first-team');
  const [currentSquad, setCurrentSquad] = useState(null);
  const isFirstTeam = selectedTeam === 'first-team';
  const isManagement = (position) => { return  (position === 'manager' || position === 'coach' || position === 'assistant manager')};

  useEffect(() => {

    const fetchData = async () => {
      // Fetch management data
      const managementQuery = `*[_type == "player" && (position == 'manager' || position == 'coach' || position == 'assistant manager')] {
        "id": _id,
        "image": image.asset->url,
        name,
        position,
        team
      }`;
      // Fetch player data
      const playersQuery = `*[_type == "player"] {
        "id": _id,
        "image": image.asset->url,
        name,
        position,
      }`;

      const management = await client.fetch(managementQuery, { isFirstTeam });
      const players = await client.fetch(playersQuery, { isFirstTeam });

      // Organize players by position
      const goalkeepers = players.filter((p) => p.position === 'goalkeeper');
      const defenders = players.filter((p) => p.position === 'defender');
      const midfield = players.filter((p) => p.position === 'midfielder');
      const attack = players.filter((p) => p.position === 'attacker');

      // Update state with organized data
      setCurrentSquad({
        management,
        goalkeepers,
        defenders,
        midfield,
        attack,
      });
    };

    fetchData().catch((error) => {
      console.error('Error fetching squad data:', error);
      setCurrentSquad({
        management: [],
        goalkeepers: [],
        defenders: [],
        midfield: [],
        attack: [],
      }); // Fallback to empty arrays on error
    });
  }, [selectedTeam]);

  // Display loading state if data is not yet fetched
  if (!currentSquad) {
    return <div>Loading...</div>;
  }

  // PlayerCard component (unchanged)
  const PlayerCard = ({ player }) => (
    <div className="player-card">
      <div className="player-image">
        <img src={player.image} alt={player.name} />
      </div>
      <div className="player-info">
        <h3>{player.name}</h3>
        {isManagement(player.position) && <p>{player.position[0].toUpperCase()}{player.position.slice(1)}</p>}
      </div>
    </div>
  );

  // SquadSection component (unchanged)
  const SquadSection = ({ title, players }) => (
    <section className="squad-section">
      <h2>{title}</h2>
      <div className="players-grid">
        {players.map((player) => (
          <PlayerCard key={player.id} player={player} />
        ))}
      </div>
    </section>
  );

  // Render the squad page
  return (
    <div className="squad-page">
      <div className="team-toggle">
        <button
          className={selectedTeam === 'first-team' ? 'active' : ''}
          onClick={() => setSelectedTeam('first-team')}
        >
          First Team
        </button>
        <button
          className={selectedTeam === 'reserves' ? 'active' : ''}
          onClick={() => setSelectedTeam('reserves')}
        >
          Reserves
        </button>
      </div>

      <div className="squad-content">
        { isFirstTeam ?
        <>
          <SquadSection title="Management" players={currentSquad.management} />
          <SquadSection title="Goalkeepers" players={currentSquad.goalkeepers} />
          <SquadSection title="Defenders" players={currentSquad.defenders} />
          <SquadSection title="Midfielders" players={currentSquad.midfield} />
          <SquadSection title="Forwards" players={currentSquad.attack} />
        </> :
        <>
        Coming soon...
        </>
        }
      </div>
    </div>
  );
};

export default Squad;